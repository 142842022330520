import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactJson from 'react-json-view';
import AppTable from '../../../../../../components/crud/AppTable';
import WebhookLogsModel from './model/WebhookLogsModel';
import DateHelper from '../../../../../../util/DateHelper';
import ModalDialog from '../../../../../../components/modal/ModalDialog';

export default class WebhookLogs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showInfoModal: false,
      webhookData: null
    };
  }

  onClose() {
    this.setState({
      showInfoModal: false
    });
  }

  // Utility function to check if the data is JSON
  isJSON(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  render() {
    return (
      <div className="dashboard-management">
        {this.props.vendor && this.props.entity && this.props.entityId && (
          <AppTable
            title="Webhook logs"
            onInit={tableComponent => (this._tableComponent = tableComponent)}
            filter={[
              {
                name: 'term',
                type: 'term'
              }
            ]}
            order={[
              {
                field: 'id',
                direction: 'desc'
              }
            ]}
            model={WebhookLogsModel}
            params={{
              vendor: this.props.vendor,
              entity: this.props.entity,
              entityId: this.props.entityId
            }}
            renderCell={(column, item, index) => {
              if (column.name === 'created') {
                return DateHelper.fullDate(item.createdAt);
              }
            }}
            getColumnActions={(member, index) => {
              return (
                <button
                  type="button"
                  className="button-info"
                  onClick={() =>
                    this.setState(
                      {
                        webhookData: this.isJSON(member.data)
                          ? JSON.parse(member.data)
                          : member.data
                      },
                      () => {
                        this.setState({
                          showInfoModal: true
                        });
                      }
                    )
                  }
                >
                  Details
                </button>
              );
            }}
          />
        )}
        <ModalDialog
          show={this.state.showInfoModal}
          onClose={() => this.onClose.bind(this)}
        >
          <div className="d-flex flex-column align-items-center justify-content-center">
            <div style={{ maxHeight: 600, overflowY: 'auto' }}>
              <ReactJson src={this.state.webhookData} theme="google" />
            </div>
            <button
              type="button"
              onClick={this.onClose.bind(this)}
              className="button-info mt-2"
            >
              Close
            </button>
          </div>
        </ModalDialog>
      </div>
    );
  }
}

WebhookLogs.contextTypes = {
  router: PropTypes.object
};
