/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { AppIcon } from '@launchpad';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import React, { Component } from 'react';

const theme = createMuiTheme({
  // For Underline Color After Click
  palette: {
    primary: { main: 'rgba(255, 255, 255, 1)' }
  },
  typography: {
    body1: {
      color: 'rgba(255, 255, 255, 1) !important',
      marginBottom: '0 !important'
    }
  },
  // For Underline Hover Color
  overrides: {
    MuiOutlinedInput: {
      notchedOutline: {
        borderColor: '#fff',
        borderWidth: 1
      },
      root: {
        color: 'rgba(255, 255, 255, 1)',
        '&$focused': {
          borderColor: '#fff'
        },
        '&:hover:not($disabled):not($focused):not($error) $notchedOutline': {
          borderColor: '#fff'
        }
      }
    },
    MuiFormLabel: {
      root: {
        color: 'rgba(255, 255, 255, 1)'
      }
    }
  }
});

export default class InputField extends Component<any, any> {
  /**
   * Constructor
   *
   * @param props
   */
  constructor(props) {
    super(props);

    // Initial state
    this.state = {
      fieldType: this.props.type || 'text',
      showPassword: false
    };
  }

  componentDidMount() {
    this.setState({
      fieldType: this.props.type
    });
  }

  /**
   * Handle input change
   *
   * @param e
   */
  handleInputChange(e: any) {
    if (this.props.customInputValidation) {
      this.customHandleInputChange(e, this.props.customInputValidation);
    }
    if (this.props.handler) {
      this.props.handler.handleInputChange(e);
    } else {
      this.props.onChange(e);
    }
  }

  getClassName() {
    const classNames = ['input-group'];
    if (this.props.fullWidth) {
      classNames.push('fulled');
    }

    return classNames.join(' ');
  }

  getValidationError() {
    const messages: any[] = [];
    // Read name from inputLabelProps when input is added as customInput in DatePicker
    const fieldName = this.props.name || this.props.InputLabelProps?.name;
    if (this.props.handler) {
      this.props.handler
        .getErrors()
        .filter((x: { field: any }) => x.field === fieldName)
        .forEach((error: { message: any }) => messages.push(error.message));
    }
    if (messages.length === 0) {
      return null;
    }
    return (
      <div className="validation-message" key={`${fieldName}-error`}>
        {messages.join('<br/>')}
      </div>
    );
  }

  customHandleInputChange(
    e: React.ChangeEvent<HTMLInputElement>,
    validators: Array<
      | 'onlyEnglishAlphabet'
      | 'onlyEnglishAlphabetAndNumbers'
      | 'onlyUpperCase'
      | 'capitalize'
    >
  ) {
    const { value } = e.target;

    const regex = validators.includes('onlyEnglishAlphabet')
      ? /^[a-zA-Z\s]*$/
      : /^[a-zA-Z0-9\s]*$/;

    if (
      validators.includes('onlyEnglishAlphabet') ||
      validators.includes('onlyEnglishAlphabetAndNumbers')
    ) {
      if (!regex.test(value)) {
        e.target.value = value.substring(0, value.length - 1);
        return;
      }
    }
    if (validators.includes('onlyUpperCase')) {
      e.target.value = value.toUpperCase();
    } else if (validators.includes('capitalize')) {
      const capitalizeWords = value
        .split(/\s+/)
        .map(s => s.charAt(0).toUpperCase() + s.substring(1).toLowerCase())
        .join(' ');

      e.target.value = capitalizeWords;
    }
  }

  toggleShowPassword() {
    this.setState(
      {
        showPassword: !this.state.showPassword
      },
      () => {
        if (this.state.showPassword) {
          this.setState({
            fieldType: 'text'
          });
        } else {
          this.setState({
            fieldType: 'password'
          });
        }
      }
    );
  }

  /**
   * Render input
   *
   * @returns {XML}
   */
  render() {
    // let className = this.getClassName();
    const validationError = this.getValidationError();

    return (
      <div
        className={this.props.className}
        style={{ position: 'relative' }}
        id={validationError ? 'outlined-error' : ''}
      >
        <MuiThemeProvider
          theme={this.props.muiTheme ? this.props.muiTheme : theme}
        >
          <TextField
            error={!!validationError}
            ref="actualInput"
            variant="outlined"
            autoComplete={this.props.autoComplete}
            label={this.props.label}
            className={this.props.className}
            type={this.state.fieldType}
            placeholder={this.props.placeholder}
            name={this.props.name}
            inputProps={this.props.inputProps}
            margin="normal"
            // InputLabelProps={{
            //   shrink: true,
            // }}
            onFocus={this.props.onFocus}
            onBlur={this.props.onBlur}
            value={this.props.value}
            onChange={this.handleInputChange.bind(this)}
            {...this.props.materialProps}
            InputProps={
              this.props.prefix
                ? {
                    startAdornment: (
                      <InputAdornment position="start">
                        {this.props.prefix}
                      </InputAdornment>
                    )
                  }
                : null
            }
          />
        </MuiThemeProvider>
        {this.props.type === 'password' && (
          <AppIcon
            onClick={() => this.toggleShowPassword()}
            name={!this.state.showPassword ? 'fa.eye' : 'fa.eye-slash'}
            style={{
              fontSize: 18,
              color: 'white',
              position: 'absolute',
              top: '42%',
              right: '15px',
              cursor: 'pointer'
            }}
          />
        )}
        {validationError}
      </div>
    );
  }
}
