/* eslint-disable no-nested-ternary */
import React, { Component } from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import UserRolesSummary from '@launchpad/modules/users/components/UserRolesSummary';
import DeleteButton from '@launchpad/components/crud/DeleteButton';
import ToastHelper from '@launchpad/util/ToastHelper';
import { date } from '../../../../../../@launchpad/util/DateHelper';
import { LPOverridable } from '../../../../../../@launchpad/components';
import UsersModel from '../../models/UsersModel';
import { LoadingOverlay } from '../../../../../../@launchpad/index';

const VendorSyncButton = ({ user, onTrigger, onSyncDone }) => {
  if (!user?.vendorReference) return null;

  const vendorSync = async () => {
    onTrigger();
    try {
      await new UsersModel().triggerVendorSync(user.id);
    } catch (e) {
      console.error(e);
    } finally {
      if (onSyncDone) {
        onSyncDone();
      }
    }
  };

  return (
    <DeleteButton
      btnText="Vendor sync"
      onConfirm={vendorSync}
      text={`Are you sure you wish to trigger vendor sync for user "${user.username}"?`}
      style={{ width: 200 }}
    />
  );
};

const DeleteUserButton = ({ user, onTrigger, onSyncDone }) => {
  const deleteUser = async () => {
    onTrigger();
    try {
      await new UsersModel().triggerDeleteUser(user.id);
    } catch (e) {
      console.error(e);
    } finally {
      if (onSyncDone) {
        onSyncDone();
      }
    }
  };

  return (
    <DeleteButton
      btnText="Delete User"
      onConfirm={deleteUser}
      text={`Are you sure you wish to delete user "${user.username}"?`}
      style={{ width: 200 }}
    />
  );
};

const UserResetPasswordButton = ({ user, onTrigger, onSyncDone }) => {
  const userResetPassword = async () => {
    onTrigger();
    try {
      const result = await new UsersModel().triggerResetPasswordForUser(
        user.id
      );
      if (result) {
        ToastHelper.show(
          'You have successfully sent an email to the user to reset the password.',
          'success'
        );
      }
    } catch (e) {
      console.error(e);
    } finally {
      if (onSyncDone) {
        onSyncDone();
      }
    }
  };

  return (
    <DeleteButton
      btnText="Change password"
      onConfirm={userResetPassword}
      text={`Are you sure you wish to change password for user "${user.username}"?`}
      style={{ width: 200 }}
    />
  );
};

class CustomerBasicInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
      isLoading: false
    };
  }

  // onApproveUser(id) {
  //   this.setState({ isLoading: true });
  //   new UsersModel()
  //     .approveRejactKYC(id, 'VERIFIED')
  //     .then(() => {
  //       console.log('inside then...');
  //       this.props.onRefresh();
  //     })
  //     .catch(error => {
  //       console.log(error);
  //     })
  //     .finally(() => {
  //       console.log('inside finally...');
  //       this.setState({ isLoading: false });
  //     });
  // }

  // onRejectUser(id) {
  //   this.setState({ isLoading: true });
  //   new UsersModel()
  //     .approveRejactKYC(id, 'REJECTED')
  //     .then(() => {
  //       this.props.onRefresh();
  //     })
  //     .catch(error => {
  //       console.log(error);
  //     })
  //     .finally(() => this.setState({ isLoading: false }));
  // }

  render() {
    const { user, profile, history } = this.props;

    if (this.state.redirect) {
      return <Redirect to={`/user-details/${user.id}`} />;
    }

    const kycStatus = user.kycStatus || '';
    const newKycStatus = kycStatus.replace(/#|_/g, ' ');
    const pannovateAllowed =
      profile &&
      (profile.username.includes('@pannovate.') ||
        profile.username.includes('syllo.io'));

    return (
      <div className="main" key={user.id}>
        <div className="dark-blur" />
        <div className="customers-info">
          <div className="customer-details">
            <div>
              <h2 className="customer-name">
                <span className="company">
                  {user.firstName} {user.lastName}
                </span>
              </h2>
            </div>
            {/* <div>
              <div className="bottom" style={{ alignItems: 'center' }}>
                <Link
                  to={`/users/all-users/edit-user/${user.id}`}
                  className="button-info"
                  style={{ marginRight: 20 }}
                >
                  Edit
                </Link>
              </div>
            </div> */}
          </div>

          {!user?.id || this.state.isLoading ? (
            <div style={{ position: 'relative', minHeight: 400 }}>
              <LoadingOverlay />
            </div>
          ) : (
            <div className="customers-main">
              <div className="row">
                <div className="col">
                  <div className="padded">
                    <LPOverridable
                      name="user.details.basic.before"
                      user={user}
                    />
                    <div className="row text-white">
                      <div className="col-lg-6" style={{ marginBottom: 20 }}>
                        <h4>Basic Info</h4>
                        <hr />
                        <dl>
                          <dt>First name:</dt>
                          <dd>{user.firstName || '-'}</dd>
                          <dt>Last name:</dt>
                          <dd>{user.lastName || '-'}</dd>
                          <dt>Username:</dt>
                          <dd>{user.username || '-'}</dd>
                          <dt>E-mail address:</dt>
                          <dd>{user.email || '-'}</dd>
                          <dt>Customer id:</dt>
                          <dd>{user.id || '-'}</dd>
                          <dt>Birth date:</dt>
                          <dd>{date(user.birthDate, 'D / MMMM / Y') || '-'}</dd>
                          <dt>Onboarding:</dt>
                          <dd>
                            {date(user.registeredAt, 'D / MMMM / Y HH:mm') ||
                              '-'}
                          </dd>
                          <dt>Vendor reference:</dt>
                          <dd>{user.vendorReference || '-'}</dd>
                        </dl>

                        {pannovateAllowed && (
                          <div className="mt-2">
                            <VendorSyncButton
                              user={user}
                              onTrigger={() =>
                                this.setState({ isLoading: true })
                              }
                              onSyncDone={() => window.location.reload()}
                            />
                          </div>
                        )}
                        <div className="mt-2">
                          <UserResetPasswordButton
                            user={user}
                            onTrigger={() => this.setState({ isLoading: true })}
                            onSyncDone={() =>
                              this.setState({ isLoading: false })
                            }
                          />
                        </div>
                        <div className="mt-2">
                          <DeleteUserButton
                            user={user}
                            onTrigger={() => this.setState({ isLoading: true })}
                            onSyncDone={() => history.push('/')}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6" style={{ marginBottom: 20 }}>
                        <UserRolesSummary userId={user.id} />
                        <h4>KYC</h4>
                        <hr />
                        <dl>
                          <dt>Status:</dt>
                          <dd>{newKycStatus.toUpperCase()}</dd>
                        </dl>
                        {/* <div
                          style={{ maxWidth: 200, margin: 'auto 0 auto auto' }}
                        >
                          {this.state.isLoading ? (
                            <CircularProgress
                              className="circle-progress"
                              size={20}
                            />
                          ) : user.kycStatus === 'verified' ? (
                            <DeleteButton
                              btnText="Reject"
                              block
                              onConfirm={() => this.onRejectUser(user.id)}
                              text={`Are you sure you wish to reject this user "${`${user.firstName} ${user.lastName}`}"?`}
                            />
                          ) : (
                            <DeleteButton
                              btnText="Approve"
                              block
                              onConfirm={() => this.onApproveUser(user.id)}
                              text={`Are you sure you wish to approve this user "${`${user.firstName} ${user.lastName}`}"?`}
                            />
                          )}
                        </div> */}
                      </div>
                    </div>
                    <div className="row text-white">
                      <div className="col-lg-6" style={{ marginBottom: 20 }}>
                        <h4>Address Info</h4>
                        <hr />
                        <dl>
                          <dt>Address Line One:</dt>
                          <dd>{user.addressOne || '-'}</dd>
                          <dt>Address Line Two:</dt>
                          <dd>{user.addressTwo || '-'}</dd>
                          <dt>City:</dt>
                          <dd>{user.city || '-'}</dd>
                          <dt>Postcode:</dt>
                          <dd>{user.postCode || '-'}</dd>
                          <dt>Country code:</dt>
                          <dd>{user.countryCode || '-'}</dd>
                          <dt>Mobile:</dt>
                          <dd>{user.phoneNumber || '-'}</dd>
                        </dl>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  profile: state.auth.profile
});

export default connect(mapStateToProps)(withRouter(CustomerBasicInfo));
