import { AppConfig } from '@launchpad/types';

const config: AppConfig = {
  env: process.env.REACT_APP_ENVIRONMENT || 'dev',
  url: process.env.REACT_APP_URL || 'http://localhost',
  authStrategy: 'access-token',
  ignorePermissions: true,
  vendor: 'ACCOMPLISH'
};

export default config;
