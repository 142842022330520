import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Api from '@launchpad/logic/api/Api';
import UserCards from '@launchpad/modules/users/all-users/pages/details/tabs/UserCards';
import UserAccounts from '@launchpad/modules/users/all-users/pages/details/tabs/UserAccounts';
import WebhookLogs from '@launchpad/modules/users/all-users/pages/details/tabs/WebhookLogs';
import CustomerBasicInfo from './tabs/CustomerBasicInfo';
import BreadcrumbHelper from '../../../../../@launchpad/util/BreadcrumbHelper';
import AppTabs from '../../../../../@launchpad/components/crud/tabs/AppTabs';
import KycInfo from './tabs/KycInfo';

export default class UserDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {},
      activeTab: 'basic'
    };
  }

  componentDidMount() {
    this._getUserDetails();
  }

  getTabs(user) {
    const basicInfo = (
      <CustomerBasicInfo onRefresh={() => this._getUserDetails()} user={user} />
    );
    const allTabs = [
      {
        id: 'basic',
        icon: 'info',
        title: 'Basic Info',
        component: basicInfo
      },
      {
        id: 'kyc',
        icon: 'file-text',
        title: 'KYC Info',
        component: (
          <KycInfo
            onRefresh={() => this._getUserDetails()}
            user={user}
            {...this.props}
          />
        )
      },
      {
        id: 'accounts',
        icon: 'address-card',
        title: 'Accounts',
        component: <UserAccounts user={user} {...this.props} />
      },
      {
        id: 'cards',
        icon: 'credit-card',
        title: 'Cards',
        component: <UserCards user={user} {...this.props} />
      },
      {
        id: 'webhook-logs',
        icon: 'magnet',
        title: 'Webhook logs',
        component: (
          <WebhookLogs
            vendor="ACCOMPLISH"
            entity="USER"
            entityId={user.id}
            {...this.props}
          />
        )
      }
    ];
    return allTabs;
  }

  async _getUserDetails() {
    const { id } = this.props.match.params;
    const getUrl = `admin/users/${id}`;

    try {
      const result = await Api.call(getUrl, 'GET');
      this.setState({
        user: result.data
      });
    } catch (e) {
      console.error(e);
    }
  }

  _renderContent(userDetails) {
    if (userDetails.firstName) {
      BreadcrumbHelper.setCrumbs([
        {
          label: `${userDetails.firstName} ${userDetails.lastName}`
        }
      ]);
    }
    return (
      <div>
        <h1>
          <i className="fa fa-info" aria-hidden="true" /> User Info:
          <span className="ml-2">
            {userDetails.firstName} {userDetails.lastName}
          </span>
        </h1>
        <AppTabs
          onTabChange={activeTab => this.setState({ activeTab })}
          activeTab={this.state.activeTab}
          tabs={this.getTabs(userDetails)}
        />
      </div>
    );
  }

  render() {
    return (
      <div className="dashboard-management">
        {this._renderContent(this.state.user)}
      </div>
    );
  }
}

// Inject router
UserDetails.contextTypes = {
  router: PropTypes.object
};
