import { IdButton } from '@launchpad/components/crud/AppTable';
import Api from '@launchpad/logic/api/Api';
import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

type UserRole = {
  id: string;
  role: {
    id: string;
    name: string;
    slug: string;
  };
  user: {
    id: string;
    firstName: string;
    lastName: string;
  };
  entity?: string;
  entityId?: string;
};

const printRow = (row: UserRole) => {
  return (
    <tr>
      <td>
        <IdButton value={row.id} />
      </td>
      <td>{row.role.name}</td>
      <td>{printEntity(row)}</td>
    </tr>
  );
};

const printOtherRoleRow = (row: UserRole) => {
  return (
    <tr>
      <td>
        <IdButton value={row.id} />
      </td>
      <td>{row.role.name}</td>
      <td>
        <Link
          className="userCardLink"
          target="_blank"
          to={`/user-details/${row.user.id}`}
        >
          {[row.user.firstName, row.user.lastName].filter(Boolean).join(' ')}
        </Link>
      </td>
    </tr>
  );
};

const printEntity = (row: UserRole) => {
  switch (row.entity?.toUpperCase()) {
    case 'USER':
      return (
        <Link
          className="userCardLink"
          target="_blank"
          to={`/user-details/${row.entityId}`}
        >
          User: {row.entityId}
        </Link>
      );
    case 'ACCOUNT':
      return (
        <Link
          className="userCardLink"
          target="_blank"
          to={`/accounts/details/${row.entityId}`}
        >
          Account: {row.entityId}
        </Link>
      );
    case 'PAYMENT_DEVICE':
      return (
        <Link
          className="userCardLink"
          target="_blank"
          to={`/cards/details/${row.entityId}`}
        >
          Payment Device: {row.entityId}
        </Link>
      );
    default:
      return '-';
  }
};

const DirectUserRoles = (props: { userId: string }): ReactElement => {
  const [userRoles, setUserRoles] = useState<UserRole[]>([]);
  const [loaded, setLoaded] = useState<boolean>(false);

  const loadUserRoles = useCallback(async () => {
    const response: { data: UserRole[] } = (await Api.call(
      `admin/user-roles?userId=${props.userId}`,
      'GET'
    )) as { data: UserRole[] };
    setUserRoles(response.data);
    setLoaded(true);
  }, [props.userId]);

  useEffect(() => {
    loadUserRoles();
  }, [props.userId, loadUserRoles]);
  return (
    <div>
      <h5>User owned roles</h5>
      <table className="table" style={{ width: '100%' }}>
        <tbody>
          <tr className="tr-dark-bg">
            <th style={{ color: 'white' }}>Id</th>
            <th style={{ color: 'white' }}>Role</th>
            <th style={{ color: 'white' }}>On</th>
          </tr>
          {userRoles && userRoles.length > 0 ? (
            userRoles.map(r => printRow(r))
          ) : (
            <tr>
              <td colSpan={3}>
                <em>{!loaded ? 'loading...' : '- no items -'}</em>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

const OtherRoles = (props: { userId: string }): ReactElement => {
  const [userRoles, setUserRoles] = useState<UserRole[]>([]);
  const [loaded, setLoaded] = useState<boolean>(false);

  const loadUserRoles = useCallback(async () => {
    const response: { data: UserRole[] } = (await Api.call(
      `admin/user-roles?entityId=${props.userId}&entity=USER`,
      'GET'
    )) as { data: UserRole[] };
    setUserRoles(response.data);
    setLoaded(true);
  }, [props.userId]);

  useEffect(() => {
    loadUserRoles();
  }, [props.userId, loadUserRoles]);
  return (
    <div>
      <h5>Other roles related to user</h5>
      <table className="table" style={{ width: '100%' }}>
        <tbody>
          <tr className="tr-dark-bg">
            <th style={{ color: 'white' }}>Id</th>
            <th style={{ color: 'white' }}>Role</th>
            <th style={{ color: 'white' }}>Owner</th>
          </tr>
          {userRoles && userRoles.length > 0 ? (
            userRoles.map(r => printOtherRoleRow(r))
          ) : (
            <tr>
              <td colSpan={3}>
                <em>{!loaded ? 'loading...' : '- no items -'}</em>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

const UserRolesSummary = (props: { userId: string }): ReactElement => {
  return (
    <div>
      <h4>Related roles</h4>
      <hr />
      <DirectUserRoles userId={props.userId} />
      <OtherRoles userId={props.userId} />
    </div>
  );
};

export default UserRolesSummary;
