import URL from 'url-parse';

const Config = {
  env: process.env.REACT_APP_ENVIRONMENT,
  // url: process.env.REACT_APP_URL || ''
  url:
    process.env.REACT_APP_URL ||
    'https://backend.lplite.dev.pannovate.net/admin/api/',
  authStrategy: 'access-token',
  doNotMergeModules: false,
  ignorePermissions: false,
  policiesEnabled: false,
  vendor: 'ACCOMPLISH',
  lpliteUserDetailsTabs: ['basic', 'kyc', 'devices'],
  vendorReportVersion: 1,
  organisationReportsType: ['KYB', 'KYB_REPORT'],
  organisationUboReportsType: ['KYC', 'PEP', 'SIS', 'COMPARE', 'DETAILS_CHECK']
};

const env =
  Config.env ||
  (!process.env.NODE_ENV || process.env.NODE_ENV === 'development'
    ? 'dev'
    : 'live');

const publicUrl = new URL(Config.url).origin;

const common = {
  resetDB: false,
  disablePasscode: false,
  forceIntros: false,
  publicUrl,
  passcodeLength: 6,
  cardRatio: 1.56,
  physicalCardCost: 7.5,
  maxTopUpValue: 250,
  minTopUpValue: 10
};

const configuration = {
  ...common,
  checkout_url: 'https://sandbox.checkout.com/api2/v2/tokens/card',
  checkout_public_key: 'pk_test_24166a6e-18f7-4405-b42a-d74aa75dc05d',
  ...Config
};

const config = {
  env,

  // Preloaded configuration
  ...configuration,

  cards: {
    cardPrice: {
      GBP: 10
    }
  },
  topUp: {
    min: 10,
    max: 200
  },

  facebook: {},
  twitter: {},
  linkedin: {},

  /**
   * Merge data with current object
   *
   * @param {Object} result
   */
  async merge(result) {
    Object.assign(this, result);
  }
};

export default config;
