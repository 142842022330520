import React from 'react';
import { Link } from 'react-router-dom';
import { AppBadge } from '@launchpad';
import AppTable from '@launchpad/components/crud/AppTable';
import { currency } from '@launchpad/util/NumberHelper';
import AccountsModel from '@launchpad/modules/accounts/models/AccountModel';

export default class UserAccounts extends React.Component {
  _tableComponent;

  _renderStatus(item) {
    switch (item.status?.toLowerCase()) {
      case 'active':
      case 'ok':
        return <AppBadge text="OK" icon="check" />;
      case 'locked':
        return <AppBadge text="Locked" icon="lock" type="danger" />;
      case 'closed':
        return <AppBadge text="Closed" icon="ban" type="danger" />;
      case 'inactive':
        return <AppBadge text="Inactive" icon="ban" type="danger" />;
      case 'pending_activation':
        return (
          <AppBadge text="Pending activation" icon="clock-o" type="info" />
        );
      default:
        return null;
    }
  }

  render() {
    const inner = (
      <>
        <AppTable
          icon="address-card"
          title="Accounts"
          onInit={tableComponent => (this._tableComponent = tableComponent)}
          params={{
            // userId: this.props.user ? this.props.user.id : undefined,
            userId: this.props.match.params.id
              ? this.props.match.params.id
              : undefined
          }}
          filter={[
            {
              name: 'term',
              type: 'term'
            }
          ]}
          renderCell={(column, item, index) => {
            switch (column.name) {
              case 'availableBalance':
                return currency(item.availableBalance, item.currency);
              case 'status':
                return this._renderStatus(item);
              case 'balance':
                return currency(item.availableBalance, item.currency);
              case 'iban':
                return item.iban || '-';
              case 'accountNumber':
                return item.accountNumber || '-';
              default:
            }
          }}
          getTableColumns={() => [
            {
              name: 'id',
              label: 'Id',
              sortable: false,
              type: 'field'
            },
            {
              name: 'accountNumber',
              label: 'Account number',
              sortable: false,
              type: 'field'
            },
            {
              name: 'name',
              label: 'Name',
              sortable: false,
              type: 'field'
            },
            {
              name: 'iban',
              label: 'IBAN',
              sortable: false,
              type: 'field'
            },
            {
              name: 'availableBalance',
              label: 'Available balance',
              sortable: false,
              type: 'field'
            },
            {
              name: 'status',
              label: 'Status',
              sortable: false,
              type: 'field'
            },
            {
              name: null,
              label: 'Actions',
              sortable: false,
              type: 'actions'
            }
          ]}
          model={AccountsModel}
          getColumnActions={(member, index) => {
            return (
              <div>
                <Link
                  to={`/accounts/details/${member.id}`}
                  className="button-info"
                >
                  Details
                </Link>
              </div>
            );
          }}
        />
      </>
    );

    if (this.props.justTable) return inner;

    return <div className="dashboard-management">{inner}</div>;
  }
}
