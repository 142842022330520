import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { currency } from '@launchpad/util/NumberHelper';
import { LoadingOverlay } from '../../../../../../@launchpad/index';
import { date } from '../../../../../../@launchpad/util/DateHelper';

class AccountBasicInfo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false
    };
  }

  render() {
    const { account } = this.props;

    let status = 'ACTIVE';
    switch (account.status) {
      case 'pending_activation':
        status = 'PENDING ACTIVATION';
        break;
      case 'active':
      case 'ok':
        status = 'ACTIVE';
        break;
      case 'locked':
        status = 'LOCKED';
        break;
      case 'stolen':
        status = 'STOLEN';
        break;
      default:
        status = account.status ? account.status.toUpperCase() : 'UNKNOWN';
    }

    if (account.locked) {
      status = 'LOCKED';
    }
    return (
      <div>
        <div className="row">
          {this.state.loading === true ? <LoadingOverlay /> : null}

          <div className="col-xl-12">
            <div className="element-with-blur">
              <div className="dark-blur" />
              <div className="box-header marginless">
                <h2>
                  <i className="fa fa-address-card" /> Account Summary
                </h2>
              </div>

              <div className="box-body text-white padded">
                <div className="row">
                  <div className="col-sm-6">
                    {!account ? (
                      <LoadingOverlay />
                    ) : (
                      <dl>
                        <dt>
                          <h3>Balance:</h3>
                        </dt>
                        <dd>
                          <h3>
                            {currency(
                              account.availableBalance,
                              account.currency
                            )}
                          </h3>
                        </dd>
                        <dt>Name:</dt>
                        <dd>{account.name}</dd>
                        <dt>Account number:</dt>
                        <dd>{account.accountNumber}</dd>
                        <dt>IBAN:</dt>
                        <dd>{account.iban}</dd>
                        <dt>Sort code:</dt>
                        <dd>{account.sortCode}</dd>
                        <dt>Swift Bic:</dt>
                        <dd>{account.swiftBic}</dd>
                        <dt>Currency:</dt>
                        <dd>{account.currency}</dd>
                        <dt>Balance created:</dt>
                        <dd>{date(account.created, 'D / MMMM / Y')}</dd>
                        <dt>Status:</dt>
                        <dd>{status}</dd>
                        <dt>Vendor:</dt>
                        <dd>{account.vendor || '-'}</dd>
                        <dt>Vendor reference:</dt>
                        <dd>{account.vendorReference || '-'}</dd>
                      </dl>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="row" style={{ marginTop: '20px' }}>
              <div className="col-xl-12">
                {/* <LastFiveTransactions accountId={this.props.account.id} /> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(AccountBasicInfo);
